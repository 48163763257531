import React from 'react';

import HeaderLegal from '../components/HeaderLegal';
import Layout from '../components/Layout';
import Footer from '../components/Footer';

export default () => (
  <Layout>
    <HeaderLegal />

    <section className="page-section bg-black" id="home">
      <div className="container">
        <div className="row align-items-center justify-content-center text-center">
          <div className="col-lg-10 align-self-end">
            <h1 className="text-white font-weight-bold my-4 py-4">
              Pixel Shard Labs Terms of Service
            </h1>
            <hr className="divider my-4" />
          </div>
          <div className="col-lg-10 text-justify text-muted">
            <p className="text-white">1. Terms</p>
            <p>
              By accessing the website at https://pixelshard.com, you are
              agreeing to be bound by these terms of service, all applicable
              laws and regulations, and agree that you are responsible for
              compliance with any applicable local laws. If you do not agree
              with any of these terms, you are prohibited from using or
              accessing this site. The materials contained in this website are
              protected by applicable copyright and trademark law.{' '}
            </p>
            <p className="text-white">2. Use License</p>

            <ul>
              <li>
                Permission is granted to temporarily download one copy of the
                materials (information or software) on Pixel Shard Labs' website
                for personal, non-commercial transitory viewing only. This is
                the grant of a license, not a transfer of title, and under this
                license you may not:
                <ul>
                  <li>modify or copy the materials;</li>
                  <li>
                    use the materials for any commercial purpose, or for any
                    public display (commercial or non-commercial);
                  </li>
                  <li>
                    attempt to decompile or reverse engineer any software
                    contained on Pixel Shard Labs' website;
                  </li>
                  <li>
                    remove any copyright or other proprietary notations from the
                    materials;
                  </li>
                  <li>
                    or transfer the materials to another person or "mirror" the
                    materials on any other server.
                  </li>
                </ul>
              </li>
              <li>
                This license shall automatically terminate if you violate any of
                these restrictions and may be terminated by Pixel Shard Labs at
                any time. Upon terminating your viewing of these materials or
                upon the termination of this license, you must destroy any
                downloaded materials in your possession whether in electronic or
                printed format.
              </li>
            </ul>

            <p className="text-white">3. Disclaimer</p>
            <ul>
              <li>
                The materials on Pixel Shard Labs' website are provided on an
                'as is' basis. Pixel Shard Labs makes no warranties, expressed
                or implied, and hereby disclaims and negates all other
                warranties including, without limitation, implied warranties or
                conditions of merchantability, fitness for a particular purpose,
                or non-infringement of intellectual property or other violation
                of rights.
              </li>
              <li>
                Further, Pixel Shard Labs does not warrant or make any
                representations concerning the accuracy, likely results, or
                reliability of the use of the materials on its website or
                otherwise relating to such materials or on any sites linked to
                this site.
              </li>
            </ul>
            <p className="text-white">4. Limitations</p>
            <p>
              In no event shall Pixel Shard Labs or its suppliers be liable for
              any damages (including, without limitation, damages for loss of
              data or profit, or due to business interruption) arising out of
              the use or inability to use the materials on Pixel Shard Labs'
              website, even if Pixel Shard Labs or a Pixel Shard Labs authorized
              representative has been notified orally or in writing of the
              possibility of such damage. Because some jurisdictions do not
              allow limitations on implied warranties, or limitations of
              liability for consequential or incidental damages, these
              limitations may not apply to you.
            </p>
            <p className="text-white">5. Accuracy of materials</p>
            <p>
              The materials appearing on Pixel Shard Labs' website could include
              technical, typographical, or photographic errors. Pixel Shard Labs
              does not warrant that any of the materials on its website are
              accurate, complete or current. Pixel Shard Labs may make changes
              to the materials contained on its website at any time without
              notice. However Pixel Shard Labs does not make any commitment to
              update the materials.
            </p>
            <p className="text-white">6. Links</p>
            <p>
              Pixel Shard Labs has not reviewed all of the sites linked to its
              website and is not responsible for the contents of any such linked
              site. The inclusion of any link does not imply endorsement by
              Pixel Shard Labs of the site. Use of any such linked website is at
              the user's own risk.
            </p>
            <p className="text-white">7. Modifications</p>
            <p>
              Pixel Shard Labs may revise these terms of service for its website
              at any time without notice. By using this website you are agreeing
              to be bound by the then current version of these terms of service.
            </p>
            <p className="text-white">8. Governing Law</p>
            <p>
              These terms and conditions are governed by and construed in
              accordance with the laws of Romania and you irrevocably submit to
              the exclusive jurisdiction of the courts in that State or
              location.
            </p>
            <hr className="divider my-4" />
          </div>
        </div>
      </div>
    </section>

    <section className="page-section bg-black" id="footer">
      <div className="container"></div>
    </section>

    <Footer />
  </Layout>
);
